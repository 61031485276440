form .div1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  background-color: #f5f5f5;
}

form .div3 {
  display: flex;
  justify-content: center;
  padding: 8px 0px;
  background-color: #f5f5f5;
}
form .span,.span4 {
  color: #F75D36;
  margin-left: 35%;
  font-size: 10px;
  opacity: 0;
}

form .shu3,
form .shu,
form .inp3,
form .name,
form .inp,
form .btn,
form .check {
  margin-left: 2vw;
  color: #333333;
}
form .name{
  width:94px;
  text-align: right;
}
form .namesub{
  width:66px;
  text-align: right;
}
form b{
  margin-right: 5px;
}
form .shu3 {
  width: 1px;
  height: 19px;
  background: #D8D8D8;
  margin-top: 1px;
}

form .shu {
  width: 1px;
  height: 19px;
  background: #D8D8D8;
}

form .inp,
form .inp3,
form .btn,
form .check {
  width: 50vw;
}

form .btn {
  border: none;
  color: #d8d8d8;
}

form .name3 {
  margin-left: 12px;
}

form .inp3 {
  margin-top: 0.5vw;
}

form div input,
form div textarea {
  background-color: transparent;
  border: none;
  outline: none;
}

input::placeholder {
  font-size: 12px;
  color: #D8D8D8;
}

input {
  margin-top: 4px;
}

textarea::placeholder {
  font-size: 12px;
  color: #D8D8D8;
}

form div b {
  color: #F75D36;
  margin-top: 4px
}

.cont {
  padding: 20px 10px;
}

.cont>p {
  color: #666666;
}

.rh {
  padding: 30px 0 20px 10px;
  font-size: 13px;
  font-weight: 600;
}

.cont>p a {
  color: #F75D36;
}

.smit {
  background-color: #ffffff;
  justify-content: space-around;

}

.smit input,
.smit a {
  color: #ffffff;
  font-size: 15px;
}

@media screen and (min-width:414px) {}

@media only screen and (max-width: 320px) {

  .success_text {
    font-weight: bold;
    margin-top: 10px;
    font-size: 22px;
  }

  .smit a,
  .smit input {
    font-size: 10px;
    color: #ffffff;
  }

  .cont p {
    font-size: 13px;
  }
}

.smit {
  margin-top: 20px;
  display: flex
}

.smit div {
  width: 35vw;
  height: 32px;
  background-color: #F75D36;
  display: flex;
  align-items: center;
  justify-content: center;
}


.success_block {
  display: none;
  margin-top: 60px;
  width: 100%;
  text-align: center;
}

.success_block img {
  width: 24vw;
}

.success_text {
  margin-top: 20px;
  font-size: 14px;
}